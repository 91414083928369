import React from "react"
import PropTypes from "prop-types"

const SimpleColumn = (props) => (
  <div className={`table__column ${props.customClass}`}>
    <div className="text-bold" dangerouslySetInnerHTML={{ __html: props.value}}></div>
    <div className="table__column__details" dangerouslySetInnerHTML={{ __html: props.details}}></div>
  </div>
)

SimpleColumn.propTypes = {
  value: PropTypes.string,
  details: PropTypes.string,
  customClass: PropTypes.string
}

export default SimpleColumn
