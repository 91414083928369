import React from "react"
import PropTypes from "prop-types"
import { CSSTransition } from 'react-transition-group';

class TileOverlay extends React.Component {
  constructor (props){
    super(props)
    this.state = {
      mealDescriptionVisible: false
    }
  }

  onClickHandler = () => {
    this.setState({ mealDescriptionVisible: !this.state.mealDescriptionVisible })
  }

  render () {
    const { mealDescription } = this.props
    const { mealDescriptionVisible } = this.state

    return (
      <div className="delivery-tile__overlay">
        {!mealDescriptionVisible && <button onClick={this.onClickHandler} className="delivery-tile__overlay-action">View Details</button>}
        {mealDescriptionVisible && 
          <div>
            <button onClick={this.onClickHandler} className="delivery-tile__overlay-close">X</button>
            {mealDescription}
          </div>
        }
      </div>
    )
  }
}

TileOverlay.propTypes = {
  mealDescription: PropTypes.string
}

export default TileOverlay
