import React from "react"

const LocationDropdown = () => {
  return (
    <div className="table__column">
      <select>
        <option>NYC: 45 Broadway</option>
      </select>
    </div>
  )
}

export default LocationDropdown
