import React from "react"
import PropTypes from "prop-types"
import ActionDropdown from './ActionDropdown'
import SimpleColumn from './SimpleColumn'
import NextPlanColumn from './NextPlanColumn'
import CycleUsageColumn from './CycleUsageColumn'
import Confirmation from './Confirmation'
import LocationDropdown from './LocationDropdown'
import axios from "axios"
import { CSSTransition } from 'react-transition-group';

class EmployeeRow extends React.Component {
  constructor (props){
    super(props)
    this.state = {
      action: props.action,
      nextPlanName: props.nextPlanName,
      confirmationVisible: false,
      plansDropdownVisible: props.action == 'change' && props.plans.length > 0
    }
  }

  onChangeActionHandler = (e) => {
    const newValue = e.target.value
    const { cancelationPath, reactivationPath, planChangePath, productOffering, employeeId, plans, nextPlanId } = this.props

    if (newValue === 'change' && plans.length > 0) {
      this.setState({ plansDropdownVisible: true })
      const plan = plans.find(p => p.id === nextPlanId)
      const planId = plan ? plan.id : plans[0].id

      this.updateSubscription(planChangePath, {
        employee_id: employeeId,
        product_offering: productOffering,
        plan_id: planId
      })
    } else {
      let url = cancelationPath
      if (newValue === 'renew') {
        url = reactivationPath
      }

      this.updateSubscription(url, { employee_id: employeeId, product_offering: productOffering })
    }
  }

  onNextPlanChangeHandler = (e) => {
    const planId = e.target.value
    const { planChangePath, productOffering, employeeId } = this.props

    this.updateSubscription(planChangePath, {
      employee_id: employeeId,
      product_offering: productOffering,
      plan_id: planId
    })
  }

  updateSubscription (path, payload) {
    const { token } = this.props

    axios.post(path, payload, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    })
      .then((result) => this.successHandler(result))
  }

  successHandler (result) {
    const { action, next_plan_name } = result.data
    this.setState({
      action,
      nextPlanName: next_plan_name,
      confirmationVisible: true,
      plansDropdownVisible: action === 'change'
    })

    window.setTimeout(() => this.setState({ confirmationVisible: false}), 2000)
  }

  render () {
    const { fullName, email, startDate, endDate, planName, plans, nextPlanId, accountsCancelationAllowed } = this.props
    const { action, nextPlanName, confirmationVisible, plansDropdownVisible } = this.state

    return (
      <div className="table__row">
        <SimpleColumn value={fullName} />
        <SimpleColumn value={email} customClass="table__column--large" />
        <SimpleColumn value={planName} />
        <SimpleColumn value={startDate} customClass="table__column--small" />
        <SimpleColumn value={endDate} customClass="table__column--small" />
        <ActionDropdown
          accountsCancelationAllowed={accountsCancelationAllowed}
          onChangeHandler={this.onChangeActionHandler}
          hasEmptyPlanList={plans.length === 0}
          action={action}
        />
        <NextPlanColumn
          nextPlanId={nextPlanId}
          plans={plans}
          plansDropdownVisible={plansDropdownVisible}
          description={nextPlanName}
          onChangeHandler={this.onNextPlanChangeHandler} />
        <CSSTransition
          in={confirmationVisible}
          timeout={300}
          unmountOnExit
        >
          <Confirmation action={action} />
        </CSSTransition>
      </div>
    )
  }
}

EmployeeRow.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  action: PropTypes.string,
  employeeId: PropTypes.string,
  planName: PropTypes.string,
  planDetails: PropTypes.string,
  nextPlanName: PropTypes.string,
  nextPlanId: PropTypes.string,
  cycleUsage: PropTypes.string,
  productOffering: PropTypes.string,
  plans: PropTypes.array,
  cancelationPath: PropTypes.string,
  reactivationPath: PropTypes.string,
  planChangePath: PropTypes.string,
  token: PropTypes.string,
  accountsCancelationAllowed: PropTypes.bool
}

export default EmployeeRow
