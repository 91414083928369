import React from "react"
import PropTypes from "prop-types"

const CycleUsageColumn = (props) => {
  return (
    <div className="table__column">
      <div dangerouslySetInnerHTML={{ __html: props.value}}></div>
    </div>
  )
}

CycleUsageColumn.propTypes = {
  value: PropTypes.string
}

export default CycleUsageColumn
