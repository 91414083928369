import React from "react"
import PropTypes from "prop-types"

class EmployeeSubscriptionDropdown extends React.Component {
  actions = [
    { value: 'renew', label: 'Renew current plan' }, { value: 'cancel', label: 'Cancel account' },
  ]

  render () {
    return (
      <select defaultValue={this.props.action} onChange={this.props.onChangeHandler}>
        {this.actions.map(action => {
          return (<option key={action.value} value={action.value}>{action.label}</option>)
        })}
      </select>
    );
  }
}

EmployeeSubscriptionDropdown.propTypes = {
  action: PropTypes.string,
  onChangeHandler: PropTypes.func
}

export default EmployeeSubscriptionDropdown
