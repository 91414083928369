import React from "react"
import PropTypes from "prop-types"

class LocationDropdown extends React.Component {
  onChangeHandler (e) {
    window.location.href = `${window.location.pathname}?location_id=${e.target.value}`
  }

  render () {
    return (
      <select onChange={this.onChangeHandler} defaultValue={this.props.selectedLocation.id}>
        {this.props.locations.map(l => {
          return (<option key={l.id} value={l.id}>{l.address}</option>)
        })}
      </select>
    )
  }
}

LocationDropdown.propTypes = {
  locations: PropTypes.array,
  selectedLocation: PropTypes.object
}

export default LocationDropdown
