import React from "react"
import PropTypes from "prop-types"
import { bool } from "prop-types"

const ActionDropdown = (props) => {
  const { action, onChangeHandler, hasEmptyPlanList, accountsCancelationAllowed } = props
  const actions = [
    { value: 'renew', label: 'Renew current plan' },
    { value: 'cancel', label: 'Cancel account' },
    { value: 'change', label: 'Change next plan' }
  ]

  return (
    <div className="table__column">
      <select defaultValue={action} onChange={onChangeHandler}>
        {actions.filter((action) => { return !hasEmptyPlanList || action.value !== 'change' })
                .filter((action) => { return accountsCancelationAllowed || action.value !== 'cancel' })
                .map(action => {
                  return (<option key={action.value} value={action.value}>{action.label}</option>)
                })
        }
      </select>
    </div>
  )
}

ActionDropdown.propTypes = {
  action: PropTypes.string,
  onChangeHandler: PropTypes.func,
  hasEmptyPlanList: PropTypes.bool,
  accountsCancelationAllowed: PropTypes.bool
}

export default ActionDropdown
