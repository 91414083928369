import React from "react"
import PropTypes from "prop-types"

const Confirmation = (props) => {
  const { action } = props

  return (
    <div className="table__confirmation table__confirmation--success">
      {action === 'renew' && 'User’s account renew'}
      {action === 'cancel' && 'User’s account canceled'}
      {action === 'change' && 'User’s account changed'}
    </div>
  )
}

Confirmation.propTypes = {
  action: PropTypes.string
}

export default Confirmation
