import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import moment from "moment"

import "react-datepicker/dist/react-datepicker.css"


class InvoicesSearch extends React.Component {
  state = {
    startDate: this.props.startDate && moment(this.props.startDate).toDate(),
    endDate: this.props.endDate && moment(this.props.endDate).toDate()
  }

  onStartDateChangeHandler = startDate => this.setState({ startDate })
  onEndDateChangeHandler = endDate => this.setState({ endDate })

  onSubmitHandler = () => {
    const startDate = this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD') : ''
    const endDate = this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : ''
    window.location.href = `${window.location.pathname}?start_date=${startDate}&end_date=${endDate}`
  }

  render () {
    return (
      <div className="invoices__search-form">
        <div className="input-group">
          <div className="input-group-addon">
            <span className="glyphicon glyphicon-calendar"></span>
          </div>
          <DatePicker
            placeholderText="Start date"
            selected={this.state.startDate}
            onChange={this.onStartDateChangeHandler}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="input-group">
          <div className="input-group-addon">
            <span className="glyphicon glyphicon-calendar"></span>
          </div>
          <DatePicker
            placeholderText="End date"
            selected={this.state.endDate}
            onChange={this.onEndDateChangeHandler}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <button id="view-invoices" onClick={this.onSubmitHandler} className="invoices__action">View</button>
      </div>
    );
  }
}

InvoicesSearch.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string
}

export default InvoicesSearch
