import React from "react"
import PropTypes from "prop-types"

const NextPlanColumn = (props) => {
  const { plansDropdownVisible, nextPlanId, onChangeHandler, description, plans } = props

  return (
    <div className="table__column">
      {plansDropdownVisible ?
          <select defaultValue={nextPlanId} onChange={onChangeHandler}>
            {plans.map(plan => {
              return (<option key={plan.id} value={plan.id}>{plan.corporate_label}</option>)
            })}
          </select>
        :
          description || '--'
        }
    </div>
  )
}

NextPlanColumn.propTypes = {
  nextPlanId: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.string,
  onChangeHandler: PropTypes.func,
  plans: PropTypes.array
}

export default NextPlanColumn
